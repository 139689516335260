import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const FinishedWorkOrderMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  startingDatetime: {
    bcKey: 'Starting_Datetime',
    type: 'date',
    required: false,
  },
  endingDatetime: {
    bcKey: 'Ending_Datetime',
    type: 'date',
    required: false,
  },
  orderSource: {
    bcKey: 'Order_Source',
    type: 'string',
    required: false,
  },
  documentDate: {
    bcKey: 'Document_Date',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  expirationDate: {
    bcKey: 'Expiration_Date',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  dueByDate: {
    bcKey: 'Due_By_Date',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  equipMain: {
    bcKey: 'Equip_Main',
    type: 'string',
    required: false,
  },
  equipPrimary: {
    bcKey: 'Equip_Primary',
    type: 'string',
    required: false,
  },
  equipSecondary: {
    bcKey: 'Equip_Secondary',
    type: 'string',
    required: false,
  },
  maintType: {
    bcKey: 'Maint_Type',
    type: 'string',
    required: false,
  },
  priorityRank: {
    bcKey: 'Priority_Rank',
    type: 'string',
    required: false,
  },
  orderDate: {
    bcKey: 'Order_Date',
    type: 'string',
    required: false,
  },
  dueDate: {
    bcKey: 'Due_Date',
    type: 'string',
    required: false,
  },
  reasonCode: {
    bcKey: 'Reason_Code',
    type: 'string',
    required: false,
  },
  problemCode: {
    bcKey: 'Problem_Code',
    type: 'string',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'Job_Task_No',
    type: 'string',
    required: false,
  },
  supervisorCode: {
    bcKey: 'Supervisor_Code',
    type: 'string',
    required: false,
  },
  requester: {
    bcKey: 'Requester',
    type: 'string',
    required: false,
  },
  ospNo: {
    bcKey: 'OSP_No',
    type: 'string',
    required: false,
  },
  postingDate: {
    bcKey: 'Posting_Date',
    type: 'string',
    required: false,
  },
  originalWoNo: {
    bcKey: 'Original_WO_No',
    type: 'string',
    required: false,
  },
  requestedServiceDate: {
    bcKey: 'Requested_Service_Date',
    type: 'string',
    required: false,
  },
  crewCode: {
    bcKey: 'Crew_Code',
    type: 'string',
    required: false,
  },
  documentTrackingNo: {
    bcKey: 'Document_Tracking_No',
    type: 'string',
    required: false,
  },
  lastModified: {
    bcKey: 'Last_Modified',
    type: 'date',
    required: false,
  },
  requestNo: {
    bcKey: 'Request_No',
    type: 'string',
    required: false,
  },
  consumptionWo: {
    bcKey: 'Consumption_WO',
    type: 'string',
    required: false,
  },
  templateNo: {
    bcKey: 'Template_No',
    type: 'string',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
  technicianName: {
    bcKey: 'Technician_Name',
    type: 'string',
    required: false,
  },
  estimatedTime: {
    bcKey: 'Estimated_Time',
    type: 'number',
    required: false,
  },
  actualTime: {
    bcKey: 'Actual_Time',
    type: 'number',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  equipmentReferenceNo: {
    bcKey: 'Equipment_Reference_No',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;
type FinishedWorkOrder = ODataObjectMap<typeof FinishedWorkOrderMap>;

const FinishedWorkOrderSchema = generateGraphQlSchema(
  'FinishedWorkOrder',
  FinishedWorkOrderMap
);

const FinishedWorkOrderQuery = generateGraphQlQuery(
  'FinishedWorkOrder',
  FinishedWorkOrderMap
);

export {
  FinishedWorkOrderSchema,
  type FinishedWorkOrder,
  FinishedWorkOrderQuery,
  FinishedWorkOrderMap,
};
