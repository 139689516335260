import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const LookupValueMap = {
  tableId: {
    required: false,
    bcKey: 'Table_Id',
    type: 'number',
  },
  equipmentGroup: {
    required: false,
    bcKey: 'Equipment_Group',
    type: 'string',
  },
  fieldId: {
    required: false,
    bcKey: 'Field_Id',
    type: 'number',
  },
  code: {
    required: false,
    bcKey: 'Code',
    type: 'string',
  },
  description: {
    required: false,
    bcKey: 'Description',
    type: 'string',
  }
} as const satisfies ODataMap;

type LookupValue = ODataObjectMap<typeof LookupValueMap>;

const LookupValueSchema = generateGraphQlSchema('LookupValue', LookupValueMap);
const LookupValueQuery = generateGraphQlQuery('LookupValue', LookupValueMap);

export { LookupValueSchema, type LookupValue, LookupValueQuery, LookupValueMap };